import { createSlice } from '@reduxjs/toolkit';
// @types
import {
  RootProductTemplateType,
  SectionProductTemplateType,
  VariableType,
} from '_types/configTemplate';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;
  rootProductTemplate: RootProductTemplateType;
  selectedSelectionId: number | string | null;
  selectedSection: SectionProductTemplateType | null;
  selectedSystemCode: string;
  selectedTypeCode: string;

  dimensionData: SectionProductTemplateType[] | null;
  placeholderImage: string | null;
  variableData: VariableType | null;
};

const initialState: InitialState = {
  isLoading: false,
  error: null,
  selectedSystemCode: '',
  selectedTypeCode: '',
  // ROOT PRODUCT TEMPLATE
  rootProductTemplate: {
    id: 'root',
    width: 420,
    height: 420,
    height2: 320,
    drawingWidth: 420,
    drawingHeight: 420,
    drawingHeight2: 320,
    frameSize: 10,
    splitDirection: null,
    newId: 1,
    sections: [
      {
        nodeType: 'section',
        parentId: 'root',
        id: 1,
        width: 400,
        height: 400,
        height2: 300,
        drawingWidth: 400,
        drawingHeight: 400,
        drawingHeight2: 300,
        frameSize: 4,
        type: 'none', // none left right  tilt,left tilt,right
        splitDirection: null, //vertical horizontal
        sections: [],
      },
    ],
  },

  selectedSelectionId: null,
  selectedSection: null,
  dimensionData: null,
  placeholderImage: null,
  variableData: null,
};

const slice = createSlice({
  name: 'configTemplate',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    updateSelectedSystemCode(state, action) {
      state.selectedSystemCode = action.payload;
    },

    updateSelectedTypeCode(state, action) {
      state.selectedTypeCode = action.payload;
    },

    // UPDATE ROOT PRODUCT TEMPLATE
    updateRootProductTemplate(state, action) {
      state.rootProductTemplate = action.payload;
    },
    updateSelectedSectionId(state, action) {
      state.selectedSelectionId = action.payload;
    },
    updateSelectedSection(state, action) {
      state.selectedSection = action.payload;
    },
    updateRootProductTemplateClean(state) {
      state.rootProductTemplate = initialState.rootProductTemplate;
    },
    updateDimensionData(state, action) {
      state.dimensionData = action.payload;
    },
    updatePlaceholderImage(state, action) {
      state.placeholderImage = action.payload;
    },

    updateVariableData(state, action) {
      state.variableData = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function updateSelectedSection(
  id: number | null,
  section: SectionProductTemplateType | null
) {
  return () => {
    try {
      dispatch(slice.actions.updateSelectedSectionId(id));
      dispatch(slice.actions.updateSelectedSection(section));
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------

export function updateRootProductTemplate(root: RootProductTemplateType) {
  return () => {
    try {
      dispatch(slice.actions.updateRootProductTemplate(root));
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------

export function updateRootProductTemplateClean() {
  return () => {
    try {
      dispatch(slice.actions.updateRootProductTemplateClean());
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------

export function updateDimensionData(data: SectionProductTemplateType[] | null) {
  return () => {
    try {
      dispatch(slice.actions.updateDimensionData(data));
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------

export function updatePlaceholderImage(data: string) {
  return () => {
    try {
      dispatch(slice.actions.updatePlaceholderImage(data));
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------

export function getPlaceholderImage(data: string) {
  return () => {
    try {
      dispatch(slice.actions.updatePlaceholderImage(data));
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------

export function updateVariableData(data: VariableType) {
  return () => {
    try {
      dispatch(slice.actions.updateVariableData(data));
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------
export function updateSelectedSystemCodeConfigTemplate(code: string) {
  return () => {
    dispatch(slice.actions.updateSelectedSystemCode(code));
  };
}
// ----------------------------------------------------------------------
export function updateSelectedTypeCodeConfigTemplate(code: string) {
  return () => {
    dispatch(slice.actions.updateSelectedTypeCode(code));
  };
}
