// Type Config
export const CONFIG_WINDOW = {
  CONFIG_1: 'CONFIG_1',
  CONFIG_2: 'CONFIG_2',
  CONFIG_3: 'CONFIG_3',
};

export const MATERIAL_OPTION = [
  'Aluminium',
  'Glass',
  'Hardware',
  'Liner',
  'System Dimension Variables',
  'Standardised Rates',
];

export const MATERIAL_TYPE = {
  ALUMINIUM: 'ALUMINIUM',
  GLASS: 'GLASS',
  HARDWARE: 'HARDWARE',
  LINER: 'LINER',
  SYSTEM_DIMENSION_VARIABLE: 'SYSTEM_DIMENSION_VARIABLE',
  STANDARDISED_RATE: 'STANDARDISED_RATE',
};

export const MATERIAL_NAME = {
  ALUMINIUM: 'Aluminium',
  GLASS: 'Glass',
  HARDWARE: 'Hardware',
  LINER: 'Liner',
  SYSTEM_DIMENSION_VARIABLE: 'System Dimension Variables',
  CALC_DIMENSION_VARIABLE: 'Calculate Dimension Variables',
  STANDARDISED_RATE: 'Standardised Rates',
};

export const PRODUCT_TEMPLATE_OPTION = ['Thermal Plus', 'Thermal Guard'];
export const PRODUCT_TEMPLATE_TYPE = {
  ThermalPlus: 'Thermal Plus',
  ThermalGuard: 'Thermal Guard',
};

export const SYSTEM_CONFIGURATION_TYPE = {
  JOINERY_SYSTEM: 'JOINERY_SYSTEM',
  COLOR: 'COLOR',
  LABOUR: 'LABOUR',
  MATERIAL_TYPE: 'MATERIAL_TYPE',
  PRODUCT_TYPE: 'PRODUCT_TYPE',
  UNIT_OF_MEASURE: 'UNIT_OF_MEASURE',
  CUT_ANGLE: 'CUT_ANGLE',
  LOCATION: 'LOCATION',
  SYSTEM_DIMENSION_VARIABLE: 'SYSTEM_DIMENSION_VARIABLE',
  COMPONENT_TYPE: 'COMPONENT_TYPE',
};

export const SYSTEM_CONFIGURATION_NAME = {
  JOINERY_SYSTEM: 'Joinery System',
  COLOR: 'Joinery Color',
  LABOUR: 'Labour Type',
  MATERIAL_TYPE: 'Material Type',
  PRODUCT_TYPE: 'Product Type',
  UNIT_OF_MEASURE: 'Unit Of Measure',
  CUT_ANGLE: 'Cut Angle',
  LOCATION: 'Location',
  COMPONENT_TYPE: 'Component Type',
};
export const CREATE_PRODUCT_TEMPLATE_TABLE_TYPE = {
  ALUMINIUM_EXTRUSIONS: 'ALUMINIUM_EXTRUSIONS',
  GLASS: 'GLASS',
  HARDWARE: 'HARDWARE',
  LINER: 'LINER',
  SUPPORT_BAR_AND_FLASHING: 'SUPPORT_BAR_AND_FLASHING',
  LABOUR: 'LABOUR',
};

export const REGEX_CONTACT_PHONE =
  /^(?![*|":<>[\]{}`\\.';@&$-])[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{2,3}[\s.-]?\d{3,5}$/m;

export const VARIABLE_TYPE = {
  BASE: 'BASE',
  CALC: 'CALC',
  DIMENSION: 'DIMENSION',
  CONSTANT: 'CONSTANT',
  SUB_FORMULA: 'SUB_FORMULA',
  BRACKET_OPEN: 'BRACKET_OPEN',
  BRACKET_CLOSE: 'BRACKET_CLOSE',
  SYSTEM_METHOD: 'SYSTEM_METHOD',
};

export const ALUMINIUM_TYPE = {
  NONE: 'NONE',
  FLASHING: 'FLASHING',
  FRAME: 'FRAME',
  METAL_LINER: 'METAL_LINER',
  CONSTANT: 'CONSTANT',
  SUPPORT_BAR: 'SUPPORT_BAR',
};

export const PARTITION_TYPE = {
  QUOTATION: 'QUOTATION',
  PRODUCTION: 'PRODUCTION',
};

export const PERMISSION = {
  ROLE_USER: 'ROLE_USER',
  ROLE_ADMIN: 'ROLE_ADMIN',
};

export const IRREGULAR_TYPE = {
  R_TRIANGLE_RIGHT: 'R_TRIANGLE_RIGHT',
  R_TRIANGLE_LEFT: 'R_TRIANGLE_LEFT',
  E_TRIANGLE: 'E_TRIANGLE',
  E_TRIANGLE_HAFT_SPLITTED: 'E_TRIANGLE_HAFT_SPLITTED',
  E_TRIANGLE_QUARTER_SPLITTED: 'E_TRIANGLE_QUARTER_SPLITTED',
  TRAPEZOID_LEFT: 'TRAPEZOID_LEFT',
  TRAPEZOID_RIGHT: 'TRAPEZOID_RIGHT',
};
