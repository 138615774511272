import { createSlice } from '@reduxjs/toolkit';
// @types
import { ProductStateType } from '../../_types/product';
// redux
import { dispatch } from '../store';
// services
import * as services from 'services/product';
import { WallTypeType } from '_types/wallType';
import { PARTITION_TYPE } from 'utils/constants';

// ----------------------------------------------------------------------

const initialState: ProductStateType = {
  isLoading: false,
  error: null,
  products: [],
  product: null,
  productTemplateOptions: [],
  productTemplate: [],
  wallTypeByProductId: [],
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    setProducts(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    setProductTemplate(state, action) {
      state.isLoading = false;
      state.productTemplate = action.payload;
    },

    setProductTemplateOption(state, action) {
      state.isLoading = false;
      state.productTemplateOptions = action.payload;
    },

    updateWallTypeByProductId(state, action) {
      state.isLoading = false;
      state.wallTypeByProductId = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { actions } = slice;

// ----------------------------------------------------------------------

export function getProductListByProjectId(
  projectId: string,
  partition: string = PARTITION_TYPE.QUOTATION
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .getProductListByProjectId(projectId, partition)
        .then((response: any) => {
          dispatch(slice.actions.setProducts(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error?.message));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function getProductTemplateListBySystemAndType(systemCode: string, typeCode: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .getProductTemplateListBySystemAndType(systemCode, typeCode)
        .then((response: any) => {
          dispatch(slice.actions.setProductTemplateOption(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error?.message));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function getProductTemplateListByThermalSentry(isHingeDoor: boolean) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .getProductTemplateListBySystemAndType('TG', isHingeDoor ? 'HD' : 'ED')
        .then((tpResponse: any) => {
          services
            .getProductTemplateListBySystemAndType('TS', isHingeDoor ? 'HD' : 'ED')
            .then((response: any) => {
              const template = [...response?.data, ...tpResponse?.data];
              dispatch(slice.actions.setProductTemplateOption(template));
            })
            .catch((error: any) => {
              dispatch(slice.actions.hasError(error?.message));
            });
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error?.message));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

export function getProductTemplateById(id: string) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response: any = await services.getProductTemplateById(id);
      return response?.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message || 'An error has occurred'));
    }
  };
}

export function updateWallTypeByProductId(wallType: WallTypeType[]) {
  return () => {
    try {
      dispatch(slice.actions.updateWallTypeByProductId(wallType));
    } catch (error) {}
  };
}

export function generateProductName(id: string, partition: string) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response: any = await services.generateProductNameById(id, partition);
      return response?.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message || 'An error has occurred'));
    }
  };
}
